export default {
  productionRequestUrl: "https://api.app.teofert.gr/v1/api",
  devRequestUrl: "https://api.app.teofert.gr/v1/api",
  tokenTypeBearer: "boolean", // type of the Auth token is bearer
  API_KEY: "string",
  ID: "_id", //id or _id
  licenceID: "string", // the id to take the activated modules,
  image_field_name: "image", // το ονομα που εχει το πεδιο με τις εικονες στο project
  maxImageSize: 2000000,
  logo: "",
  projectName: "Teofert",
};
